import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { SnackbarComponent } from './snackbar.component';
import { SnackbarService } from './snackbar.service';

const material = [MatSnackBarModule, MatButtonModule, MatIconModule];

const modules = [CommonModule, RouterModule, FlexLayoutModule];

@NgModule({
    declarations: [SnackbarComponent],
    imports: [...modules, ...material],
    providers: [SnackbarService],
    exports: [],
})
export class SnackbarModule {}
