import { NgModule } from '@angular/core';
import { Routes, RouterModule, mapToCanActivate } from '@angular/router';
import { MaintenanceGuard } from './guards/maintenance.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/self-service/self-service.module').then((m) => m.SelfServiceModule),
    },
    {
        path: 'setup',
        loadChildren: () =>
            import('./modules/business-partner-support/business-partner-support.module').then(
                (m) => m.BusinessPartnerSupportModule
            ),
        canActivate: mapToCanActivate([MaintenanceGuard]),
    },
    {
        path: 'register',
        loadChildren: () =>
            import('./modules/register-customer/register-customer.module').then((m) => m.RegisterCustomerModule),
        canActivate: mapToCanActivate([MaintenanceGuard]),
    },
    {
        path: 'forgot-username',
        loadChildren: () =>
            import('./modules/forgot-username/forgot-username.module').then((m) => m.ForgotUsernameModule),
        canActivate: mapToCanActivate([MaintenanceGuard]),
    },
    {
        path: 'register-end-customer',
        loadChildren: () =>
            import('./modules/register-end-customer/register-end-customer.module').then(
                (m) => m.RegisterEndCustomerModule
            ),
        canActivate: mapToCanActivate([MaintenanceGuard]),
    },
    {
        path: 'register-supplier',
        loadChildren: () =>
            import('./modules/register-supplier/register-supplier.module').then((m) => m.RegisterSupplierModule),
        canActivate: mapToCanActivate([MaintenanceGuard]),
    },
    {
        path: 'maintenance',
        loadChildren: () => import('./modules/maintenance/maintenance.module').then((m) => m.MaintenanceModule),
    },
    {
        path: '**',
        redirectTo: '',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
