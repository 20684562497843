import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { TranslationConfigService } from './services/translation-config.service';
import { InstanaService } from './services/instana.service';
import { environment } from '../environments/environment';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        private translationConfig: TranslationConfigService,
        private authService: AuthService,
        private instana: InstanaService,
        private router: Router
    ) {
        this.translationConfig.setLanguage();
    }

    ngOnInit(): void {
        this.authService.init();

        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.redactSecrets(val.url, ['token', 'invitationId']);
                this.instana.reportPage(this.redactSecrets(val.urlAfterRedirects, ['token', 'invitationId']));
            }
        });
        this.instana.reportMetadata('version', environment.version);
    }

    public redactSecrets(url: string, secrets: string[]) {
        const urlParts = url.split('?');
        const params = new URLSearchParams(urlParts[1]);
        secrets.forEach((secret) => {
            if (params.has(secret)) {
                params.set(secret, 'redacted');
            }
        });
        return urlParts[0] + `?${params.toString()}`;
    }
}
