import { Injectable } from '@angular/core';
import { Me } from '../modules/self-service/services/users.service';
import { environment } from '../../environments/environment';

export enum IdentityType {
    KuMa = 'KuMA',
    KuMaLite = 'KuMaLite',
    ViInt = 'ViInt',
    ViExt = 'ViExt',
    ViAcc = 'ViAcc',
    EK = 'EK',
    LiMa = 'LiMA',
}

export enum ApplicationRoles {
    Employees = 'NEVIS-Contactadministration.Admin',
    Notifications = 'CUAR_Contactadministration-Notification',
    RolesAndPermissions = 'AR_Contactadministration-RolesRights',
    BankManagement = 'CUAR_Contactadministration-BankAccount',
}

@Injectable()
export class ConfigService {
    me: Me;
    enablePurchasesAndSubscriptions = false;

    constructor() {}

    getSelfServiceConfig(): SelfServiceConfig {
        return this.getConfig(
            this.getIdentityType(),
            this.me?.applicationRoles,
            this.me?.address.countryCode?.toUpperCase()
        );
    }

    // LiteAccount
    getIdentityType(): string {
        // Check business role for lite Account
        if (this.me?.isLite) {
            return 'KuMaLite';
        }

        const identityType = this.me?.properties.find((property) => property.name === 'IdentityType');
        return identityType ? identityType.value : '';
    }

    isCustomer() {
        return this.getIdentityType() === IdentityType.KuMa;
    }

    isSupplier() {
        return this.getIdentityType() === IdentityType.LiMa;
    }

    isCustomerLite() {
        return this.getIdentityType() === IdentityType.KuMaLite;
    }

    isEndCustomer() {
        return this.getIdentityType() === IdentityType.EK;
    }

    isEmployee() {
        return this.getIdentityType().toLowerCase().startsWith('vi');
    }

    enablePersonalNotificationSettings() {
        return this.me.company.vkorg
            .split(';')
            .some((vkOrg) => environment.allowedPersonalNotificationSalesOrgs.includes(vkOrg));
    }

    enableFunctionalNotifications(applicationRoles: string[]) {
        return (
            applicationRoles?.includes(ApplicationRoles.Notifications) &&
            this.me.company.vkorg.split(';').some((vkOrg) => environment.allowedNotificationSalesOrgs.includes(vkOrg))
        );
    }

    private getConfig(identityType: string, applicationRoles?: string[], userCountry?: string): SelfServiceConfig {
        switch (identityType) {
            case IdentityType.ViExt:
                return {
                    enableDashboardRoleDisplay: false,
                    enableCompanyData: false,
                    enableBankManagement: false,
                    enableBankManagementInPersonalDataSection: false,
                    enableEmployees: false,
                    enableNotifications: false,
                    enableFunctionalNotifications: false,
                    enablePersonalNotificationSettings: false,
                    enablePersonalDataEditName: false,
                    enablePersonalDataEditLanguage: false,
                    enablePersonalDataAddress: false,
                    enablePersonalDataEditUsername: false,
                    enablePersonalDataEditPhoneNumber: false,
                    enablePersonalDataEditEmail: false,
                    enablePersonalDataLiMaHint: false,
                    enablePersonalDataRolesAndPermissionsCard: false,
                    enableOrderLimit: false,
                    enablePersonalDataPrioRouting: false,
                    enablePersonalDataDeleteAccount: false,
                    enableBestPractices: false,
                    enableRolesAndPermissions: false,
                    enableCustomRoles: false,
                    enablePurchasesAndSubscriptions: false,
                    enableEndCustomerCustomerNumber: false,
                    enableFidoSettings: false,
                    enableSettingsRequestDeletion: false,
                    enableSettings: false,
                };
            case IdentityType.ViInt:
                return {
                    enableDashboardRoleDisplay: false,
                    enableCompanyData: false,
                    enableBankManagement: false,
                    enableBankManagementInPersonalDataSection: false,
                    enableEmployees: false,
                    enableNotifications: false,
                    enableFunctionalNotifications: false,
                    enablePersonalNotificationSettings: false,
                    enablePersonalDataEditName: false,
                    enablePersonalDataEditLanguage: false,
                    enablePersonalDataAddress: false,
                    enablePersonalDataEditUsername: false,
                    enablePersonalDataEditPhoneNumber: false,
                    enablePersonalDataEditEmail: false,
                    enablePersonalDataLiMaHint: false,
                    enablePersonalDataRolesAndPermissionsCard: false,
                    enableOrderLimit: false,
                    enablePersonalDataPrioRouting: false,
                    enablePersonalDataDeleteAccount: false,
                    enableBestPractices: false,
                    enableRolesAndPermissions: false,
                    enableCustomRoles: false,
                    enablePurchasesAndSubscriptions: false,
                    enableEndCustomerCustomerNumber: false,
                    enableFidoSettings: false,
                    enableSettingsRequestDeletion: false,
                    enableSettings: false,
                };
            case IdentityType.KuMa:
                return {
                    enableDashboardRoleDisplay: false,
                    enableCompanyData: true,
                    enableBankManagement: applicationRoles?.includes(ApplicationRoles.BankManagement),
                    enableBankManagementInPersonalDataSection: false,
                    enableEmployees:
                        applicationRoles?.includes(ApplicationRoles.Employees) &&
                        !environment.forbiddenCountries.contacts.includes(this.me.company.vkorg),
                    enableNotifications:
                        this.enablePersonalNotificationSettings() ||
                        this.enableFunctionalNotifications(applicationRoles),
                    enableFunctionalNotifications: this.enableFunctionalNotifications(applicationRoles),
                    enablePersonalNotificationSettings: this.enablePersonalNotificationSettings(),
                    enablePersonalDataEditName: false,
                    enablePersonalDataEditLanguage: true,
                    enablePersonalDataAddress: false,
                    enablePersonalDataEditUsername: true,
                    enablePersonalDataEditPhoneNumber: true,
                    enablePersonalDataEditEmail: true,
                    enablePersonalDataLiMaHint: false,
                    enablePersonalDataRolesAndPermissionsCard: true,
                    enableOrderLimit: true,
                    enablePersonalDataPrioRouting: applicationRoles?.includes(ApplicationRoles.Employees),
                    enablePersonalDataDeleteAccount: false,
                    enableBestPractices: true,
                    enableRolesAndPermissions:
                        applicationRoles.includes(ApplicationRoles.RolesAndPermissions) &&
                        !environment.forbiddenCountries.rolesAndPermissions.includes(this.me.company.vkorg),
                    enableCustomRoles: true,
                    enablePurchasesAndSubscriptions: false,
                    enableEndCustomerCustomerNumber: false,
                    enableFidoSettings: false,
                    enableSettingsRequestDeletion: true,
                    enableSettings: true,
                };
            case IdentityType.KuMaLite:
                return {
                    enableDashboardRoleDisplay: false,
                    enableCompanyData: false,
                    enableBankManagement: false,
                    enableBankManagementInPersonalDataSection: false,
                    enableEmployees: false,
                    enableNotifications: false,
                    enableFunctionalNotifications: false,
                    enablePersonalNotificationSettings: false,
                    enablePersonalDataEditName: false,
                    enablePersonalDataEditLanguage: true,
                    enablePersonalDataAddress: false,
                    enablePersonalDataEditUsername: true,
                    enablePersonalDataEditPhoneNumber: true,
                    enablePersonalDataEditEmail: true,
                    enablePersonalDataLiMaHint: false,
                    enablePersonalDataRolesAndPermissionsCard: false,
                    enableOrderLimit: false,
                    enablePersonalDataPrioRouting: false,
                    enablePersonalDataDeleteAccount: true,
                    enableBestPractices: false,
                    enableRolesAndPermissions: false,
                    enableCustomRoles: false,
                    enablePurchasesAndSubscriptions: false,
                    enableEndCustomerCustomerNumber: false,
                    enableFidoSettings: false,
                    enableSettingsRequestDeletion: false,
                    enableSettings: true,
                };
            case IdentityType.EK:
                return {
                    enableDashboardRoleDisplay: false,
                    enableCompanyData: false,
                    enableBankManagement: false,
                    enableBankManagementInPersonalDataSection:
                        userCountry && environment.ekCountriesWithBankAccountManagement.includes(userCountry),
                    enableEmployees: false,
                    enableNotifications: false,
                    enableFunctionalNotifications: false,
                    enablePersonalNotificationSettings: false,
                    enablePersonalDataEditName: true,
                    enablePersonalDataEditLanguage: true,
                    enablePersonalDataAddress: true,
                    enablePersonalDataEditUsername: false,
                    enablePersonalDataEditPhoneNumber: true,
                    enablePersonalDataEditEmail: true,
                    enablePersonalDataLiMaHint: false,
                    enablePersonalDataRolesAndPermissionsCard: false,
                    enableOrderLimit: false,
                    enablePersonalDataPrioRouting: false,
                    enablePersonalDataDeleteAccount: true,
                    enableBestPractices: false,
                    enableRolesAndPermissions: false,
                    enablePurchasesAndSubscriptions: this.enablePurchasesAndSubscriptions,
                    enableCustomRoles: false,
                    enableEndCustomerCustomerNumber: this.isEndCustomer() && !!this.me?.company?.id,
                    enableFidoSettings: false,
                    enableSettingsRequestDeletion: false,
                    enableSettings: true,
                };
            case IdentityType.LiMa:
                return {
                    enableDashboardRoleDisplay: false,
                    enableCompanyData: true,
                    enableBankManagement: false,
                    enableBankManagementInPersonalDataSection: false,
                    enableEmployees: applicationRoles?.includes(ApplicationRoles.Employees),
                    enableNotifications: false,
                    enableFunctionalNotifications: false,
                    enablePersonalNotificationSettings: false,
                    enablePersonalDataEditName: false,
                    enablePersonalDataEditLanguage: true,
                    enablePersonalDataAddress: false,
                    enablePersonalDataEditUsername: true,
                    enablePersonalDataEditPhoneNumber: false,
                    enablePersonalDataEditEmail: false,
                    enablePersonalDataLiMaHint: true,
                    enablePersonalDataRolesAndPermissionsCard: true,
                    enableOrderLimit: false,
                    enablePersonalDataPrioRouting: false,
                    enablePersonalDataDeleteAccount: false,
                    enableBestPractices: false,
                    enableRolesAndPermissions: applicationRoles.includes(ApplicationRoles.RolesAndPermissions),
                    enableCustomRoles: false,
                    enablePurchasesAndSubscriptions: false,
                    enableEndCustomerCustomerNumber: false,
                    enableFidoSettings: true,
                    enableSettingsRequestDeletion: false,
                    enableSettings: true,
                };
            default:
                return {
                    enableDashboardRoleDisplay: false,
                    enableCompanyData: false,
                    enableBankManagement: false,
                    enableBankManagementInPersonalDataSection: false,
                    enableEmployees: false,
                    enableNotifications: false,
                    enableFunctionalNotifications: false,
                    enablePersonalNotificationSettings: false,
                    enablePersonalDataEditName: false,
                    enablePersonalDataEditLanguage: false,
                    enablePersonalDataAddress: false,
                    enablePersonalDataEditUsername: false,
                    enablePersonalDataEditPhoneNumber: false,
                    enablePersonalDataEditEmail: false,
                    enablePersonalDataLiMaHint: false,
                    enablePersonalDataRolesAndPermissionsCard: false,
                    enableOrderLimit: false,
                    enablePersonalDataPrioRouting: false,
                    enablePersonalDataDeleteAccount: false,
                    enableBestPractices: false,
                    enableRolesAndPermissions: false,
                    enableCustomRoles: false,
                    enablePurchasesAndSubscriptions: false,
                    enableEndCustomerCustomerNumber: false,
                    enableFidoSettings: false,
                    enableSettingsRequestDeletion: false,
                    enableSettings: false,
                };
        }
    }
}

export interface SelfServiceConfig {
    enableDashboardRoleDisplay: boolean;
    enableCompanyData: boolean;
    enableBankManagement: boolean;
    enableBankManagementInPersonalDataSection: boolean;
    enableEmployees: boolean;
    enableNotifications: boolean;
    enableFunctionalNotifications: boolean;
    enablePersonalNotificationSettings: boolean;
    enablePersonalDataEditName: boolean;
    enablePersonalDataEditLanguage: boolean;
    enablePersonalDataAddress: boolean;
    enablePersonalDataEditUsername: boolean;
    enablePersonalDataEditPhoneNumber: boolean;
    enablePersonalDataEditEmail: boolean;
    enablePersonalDataLiMaHint: boolean;
    enablePersonalDataRolesAndPermissionsCard: boolean;
    enableOrderLimit: boolean;
    enablePersonalDataPrioRouting: boolean;
    enablePersonalDataDeleteAccount: boolean;
    enableBestPractices: boolean;
    enableRolesAndPermissions: boolean;
    enableCustomRoles: boolean;
    enablePurchasesAndSubscriptions: boolean;
    enableEndCustomerCustomerNumber: boolean;
    enableFidoSettings: boolean;
    enableSettingsRequestDeletion: boolean;
    enableSettings: boolean;
}
