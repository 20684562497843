import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class StatusPageService {
    constructor(private http: HttpClient) {}

    getStatus(appId: string): Observable<AppStatus> {
        return this.http.get<AppStatus>(`${environment.http.statusPageBaseUrl}apps/${appId}/summary`);
    }
}

export interface AppStatus {
    id: string;
    name: string;
    status: 'operational' | 'under_maintenance' | 'degraded_performance' | 'partial_outage' | 'major_outage';
    next_maintenance: {
        start_date: string;
        end_date: string;
        remind_from: string;
        status: string;
    };
}
