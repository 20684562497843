import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TranslationConfigService {
    readonly defaultLanguage = 'en-GB';
    readonly supportedLanguages = [
        'cs-CZ', // Czech
        'da-DK', // Danish
        'de-DE', // German
        'en-GB', // English
        'en-US', // English
        'es-ES', // Spanich
        'fi-FI', // Finnish
        'fr-FR', // French
        'it-IT', // Italian
        'nl-NL', // Dutch
        'nb-NO', // Norwegian
        'pl-PL', // Polish
        'ro-RO', // Romanian
        'sk-SK', // Slovak
        'sv-SE', // Swedish
        'tr-TR', // Turkish
        'hr-HR', // Croatian
        'sr-RS', // Serbian
        'lt-LT', // Lithuanian,
        'sl-SI', // Slovenian
        'nl-BE', // Dutch (Belgium)
        'fr-BE', // French (Belgium)
        'uk-UA', // Ukrainian
    ];

    private currentLanguage$: BehaviorSubject<string> = new BehaviorSubject<string>(this.defaultLanguage);

    constructor(private activatedRoute: ActivatedRoute, private translate: TranslateService) {}

    setLanguage(language?: string): void {
        let supportedLanguage = this.findSupportedLanguage(language ? language : navigator.language);

        if (!supportedLanguage) {
            supportedLanguage = this.defaultLanguage;
        }

        this.currentLanguage$.next(supportedLanguage);
        this.translate.setDefaultLang(this.defaultLanguage);
        this.translate.use(supportedLanguage);
    }

    public getLanguage(): string {
        return this.currentLanguage$.value;
    }

    private findSupportedLanguage(language: string): string {
        let supportedLanguage = this.supportedLanguages.find(
            (l) => l.toLocaleLowerCase() === language.toLocaleLowerCase()
        );

        if (supportedLanguage) {
            return supportedLanguage;
        }

        supportedLanguage = this.supportedLanguages.find((l) =>
            l.toLocaleLowerCase().startsWith(language.split('-')[0].toLocaleLowerCase())
        );

        return supportedLanguage;
    }
}
