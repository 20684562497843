import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Snackbar, SnackbarAction } from './snackbar.model';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: Snackbar,
        public toastRef: MatSnackBarRef<SnackbarComponent>,
        private router: Router
    ) {}

    onAction(action: SnackbarAction): void {
        if (action.reload) {
            location.reload();
        } else if (action.url) {
            window.open(action.url, '_blank');
        } else if (action.navigateWithReload) {
            window.open(action.navigateWithReload, '_self');
        } else if (action.navigate) {
            this.router.navigate([action.navigate]);
            this.toastRef.dismiss();
        } else {
            this.toastRef.dismiss();
        }
    }

    close(): void {
        this.toastRef.dismiss();
    }
}
