<div
  *ngIf="data"
  fxLayout.gt-xs="row"
  fxLayout.xs="column"
  fxLayoutAlign.gt-xs="space-between center"
  fxLayoutAlign.xs="start stretch"
  fxLayoutGap="10px"
  class="vi-toast">
  <div
    data-ut-message
    fxFlex.sm="100%"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="message"
    [class.full-width]="!data.actions">
    <div>
      <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
      {{ data.message }}
    </div>
    <button *ngIf="!data.actions" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div
    *ngIf="data.actions"
    fxFlex.sm="100%"
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutAlign.xs="space-evenly center"
    fxLayoutGap="5px"
    class="actions">
    <ng-container *ngFor="let action of data.actions">
      <ng-container *ngIf="data.type === 'info'">
        <button
          *ngIf="action.raised"
          [id]="action.id"
          mat-raised-button
          color="primary"
          (click)="onAction(action)">
          {{ action.name }}
        </button>
        <button
          *ngIf="!action.raised"
          [id]="action.id"
          mat-button
          color="accent"
          (click)="onAction(action)">
          {{ action.name }}
        </button>
      </ng-container>
      <ng-container *ngIf="data.type === 'success' || data.type === 'error'">
        <button
          *ngIf="action.raised"
          [id]="action.id"
          mat-raised-button
          color="accent"
          (click)="onAction(action)"
        >
          {{ action.name }}
        </button>
        <button
          *ngIf="!action.raised"
          [id]="action.id"
          mat-button
          color="accent"
          (click)="onAction(action)"
        >
          {{ action.name }}
        </button>
      </ng-container>
      <ng-container *ngIf="data.type === 'warning'">
        <button
          *ngIf="action.raised"
          [id]="action.id"
          mat-raised-button
          color="accent"
          (click)="onAction(action)"
        >
          {{ action.name }}
        </button>
        <button
          *ngIf="!action.raised"
          [id]="action.id"
          mat-button
          color="warn"
          (click)="onAction(action)"
        >
          {{ action.name }}
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>
