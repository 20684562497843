import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaintenanceGuard } from './guards/maintenance.guard';
import { SnackbarModule } from './modules/shared/snackbar/snackbar.module';
import { AuthService } from './services/auth.service';
import { InterceptorService } from './services/interceptor.service';
import { StatusPageService } from './services/status-page.service';
import { TranslationConfigService } from './services/translation-config.service';
import { RECAPTCHA_BASE_URL } from 'ng-recaptcha';
import { UsersService } from './modules/self-service/services/users.service';
import { ConfigService } from './services/config.service';
import { InstanaErrorHandler } from './instana-error-handler';
import { BellModule } from '@vi/fe-bell';
import { OrderByPipe } from 'ngx-pipes';
import { AuthGuard } from './services/auth.guard';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

function HttpLoaderFactory(http: HttpBackend) {
    return new MultiTranslateHttpLoader(http, [
        // CSS translations
        {
            prefix: `${environment.http.translationsBaseUrl}phraseapp-proxy/projects/5c4867f64b2f6029d7070a82f1baef11/locales/`,
            suffix: '/download?file_format=i18next',
        },
        // @vi-succ/design-system-web translations
        {
            prefix: `${environment.http.translationsBaseUrl}phraseapp-proxy/projects/ec38b083a23c58c604a49d7f443dd7f2/locales/`,
            suffix: '/download?file_format=simple_json',
        },
    ]);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpBackend],
            },
            isolate: false,
        }),
        HttpClientModule,
        SnackbarModule,
        BellModule.forRoot({
            baseUrl: environment.globalHeader.baseUrl,
            accountAppUrl: environment.globalHeader.accountAppUrl,
            appId: 'customer-self-service',
        }),
    ],
    providers: [
        TranslationConfigService,
        {
            provide: ErrorHandler,
            useClass: InstanaErrorHandler,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true,
        },
        AuthService,
        AuthGuard,
        MaintenanceGuard,
        StatusPageService,
        {
            provide: RECAPTCHA_BASE_URL,
            useValue: 'https://recaptcha.net/recaptcha/api.js',
        },
        UsersService,
        ConfigService,
        OrderByPipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {}
}
