import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Me } from '../modules/self-service/services/users.service';
import { HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class InstanaService {
    constructor(private auth: AuthService) {
        this.redactSecrets();
        this.startSessionTracking();
    }

    private static invokeIneum(func: () => void) {
        if (environment.instana && typeof ineum === 'function') {
            func();
        }
    }

    startSessionTracking() {
        InstanaService.invokeIneum(() => ineum('trackSessions'));
    }

    redactSecrets() {
        const paramsMap = new HttpParams({
            fromString: location.hash.replace('#', ''),
        });
        const urlCsrfToken = paramsMap.get('token');
        if (urlCsrfToken) {
            location.hash = ''; // remove token form url
            this.auth.csrfToken$.next(urlCsrfToken);
        }
    }

    reportUser(userId: string, userName: string, userEmail: string) {
        InstanaService.invokeIneum(() => ineum('user', userId, userName, userEmail));
    }

    reportPage(pageName: string) {
        InstanaService.invokeIneum(() => ineum('page', pageName));
    }

    reportError(
        error: string | Error,
        meta?: {
            [key: string]: string | number | boolean;
        }
    ) {
        InstanaService.invokeIneum(() => ineum('reportError', error, { meta }));
    }

    reportMetadataObject(obj: { [key: string]: string | number | boolean }) {
        for (const [key, value] of Object.entries(obj)) {
            this.reportMetadata(key, value);
        }
    }

    reportMetadata(key: string, value: string | number | boolean) {
        InstanaService.invokeIneum(() => ineum('meta', key, value));
    }

    public reportUserAndMeta(me: Me) {
        const identityType =
            me?.properties
                .find((item: { name: string; value: string }) => item.name === 'IdentityType')
                ?.value?.toLowerCase() || '';

        // report only non employees
        if (!identityType.toLowerCase().startsWith('vi')) {
            this.reportUser(me?.id, me?.loginId, me?.contacts?.email);
            this.reportMetadataObject({
                displayName: me.name && `${me?.name?.firstName} ${me?.name?.familyName}`,
                company: me?.company?.id,
                identityType:
                    me?.properties
                        .find((item: { name: string; value: string }) => item.name === 'IdentityType')
                        ?.value?.toLowerCase() || '',
                iamRoles: me?.applicationRoles?.join(', ') || '',
            });
        }
    }
}
