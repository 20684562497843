import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard {
    constructor(private auth: AuthService) {}

    canActivate(): Observable<boolean> {
        if (this.auth.hasValidCsrfToken()) {
            return of(true);
        }
        // start csrf token authentication process
        return this.auth.fetchNewCsrfToken().pipe(map((res) => !!res.token));
    }
}
