import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { StatusPageService } from '../services/status-page.service';

@Injectable()
export class MaintenanceGuard {
    constructor(private statusPageService: StatusPageService, private router: Router) {}

    canActivate(): Observable<boolean> {
        return this.statusPageService.getStatus(environment.statusPageAppId).pipe(
            map((appStatus) => {
                if (appStatus.status === 'operational') {
                    return true;
                }

                this.router.navigate(['/maintenance']);
                return false;
            }),
            catchError((error) => {
                return of(true);
            })
        );
    }
}
