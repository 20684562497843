import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { SnackbarComponent } from './snackbar.component';
import { Snackbar, SnackbarConfig } from './snackbar.model';

export interface SnackBarConfig {
    action?: string;
    duration?: number;
    x?: 'start' | 'center' | 'end' | 'left' | 'right';
    y?: 'top' | 'bottom';
}

@Injectable()
export class SnackbarService {
    private readonly defaultConfig: SnackbarConfig;
    constructor(private snackBar: MatSnackBar) {
        this.defaultConfig = {
            type: 'info',
            duration: 3000,
            x: 'center',
            y: 'bottom',
        };
    }

    open(toast: Snackbar, config?: SnackbarConfig): Observable<MatSnackBarDismiss> {
        if (config) {
            switch (config.type) {
                case 'info':
                    return this.info(toast, config);
                case 'success':
                    return this.success(toast, config);
                case 'error':
                    return this.error(toast, config);
                case 'warning':
                    return this.warning(toast, config);
                default:
                    return this.info(toast, config);
            }
        }
        return this.info(toast);
    }

    info(toast: Snackbar, config?: SnackbarConfig): Observable<MatSnackBarDismiss> {
        return this.infoRef(toast, config).afterDismissed();
    }

    warning(toast: Snackbar, config?: SnackbarConfig): Observable<MatSnackBarDismiss> {
        return this.warningRef(toast, config).afterDismissed();
    }

    success(toast: Snackbar, config?: SnackbarConfig): Observable<MatSnackBarDismiss> {
        return this.successRef(toast, config).afterDismissed();
    }

    error(toast: Snackbar, config?: SnackbarConfig): Observable<MatSnackBarDismiss> {
        return this.errorRef(toast, config).afterDismissed();
    }

    openRef(toast: Snackbar, config?: SnackbarConfig): MatSnackBarRef<SnackbarComponent> {
        if (config) {
            switch (config.type) {
                case 'info':
                    return this.infoRef(toast, config);
                case 'success':
                    return this.successRef(toast, config);
                case 'error':
                    return this.errorRef(toast, config);
                case 'warning':
                    return this.warningRef(toast, config);
                default:
                    return this.infoRef(toast, config);
            }
        }
        return this.infoRef(toast);
    }

    infoRef(toast: Snackbar, config?: SnackbarConfig): MatSnackBarRef<SnackbarComponent> {
        return this.openSnackBar(toast, { ...config, type: 'info' });
    }

    warningRef(toast: Snackbar, config?: SnackbarConfig): MatSnackBarRef<SnackbarComponent> {
        return this.openSnackBar(toast, { ...config, type: 'warning' });
    }

    successRef(toast: Snackbar, config?: SnackbarConfig): MatSnackBarRef<SnackbarComponent> {
        return this.openSnackBar(toast, { ...config, type: 'success' });
    }

    errorRef(toast: Snackbar, config?: SnackbarConfig): MatSnackBarRef<SnackbarComponent> {
        return this.openSnackBar(toast, { ...config, type: 'error' });
    }

    private openSnackBar(toast: Snackbar, config: SnackbarConfig): MatSnackBarRef<SnackbarComponent> {
        const _config = { ...this.defaultConfig, ...config };
        const configPanelClass = _config.panelClass ? _config.panelClass : [];
        return this.snackBar.openFromComponent(SnackbarComponent, {
            duration: _config.duration,
            panelClass: [_config.type, ...configPanelClass],
            horizontalPosition: _config.x,
            verticalPosition: _config.y,
            data: { ...toast, type: _config.type },
        });
    }
}
