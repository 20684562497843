import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private CSRF_TOKEN_STORAGE_KEY = 'fp_csrf_token';

    public csrfToken$ = new BehaviorSubject(undefined);
    private fetchNewCsrfToken$: Observable<{ token: string }>;

    private httpClient: HttpClient;

    constructor(handler: HttpBackend) {
        this.httpClient = new HttpClient(handler);
    }

    public hasValidCsrfToken(): boolean {
        const token = this.getCsrfToken();
        return token && this.isCsrfTokenValid(token);
    }

    public getCsrfToken(): string {
        return this.csrfToken$.value;
    }

    init() {
        this.csrfToken$.subscribe((jwtToken) => {
            if (jwtToken) {
                localStorage.setItem(this.CSRF_TOKEN_STORAGE_KEY, jwtToken);
            }
        });

        this.fetchNewCsrfToken$ = this.getCsrfTokenRequest().pipe(
            tap({
                error: () => this.redirectToLoginScreen(),
            }),
            share()
        );

        const token = this.getCsrfTokenFromUrl() || this.getCsrfTokenFromLocalStorage();

        if (token && this.isCsrfTokenValid(token)) {
            this.csrfToken$.next(token);
            return;
        }
    }

    fetchNewCsrfToken() {
        return this.fetchNewCsrfToken$;
    }

    public getCsrfTokenRequest() {
        return this.httpClient
            .post<{ token: string }>(
                `${environment.IAMConfig.auth.baseUrl}auth/v1/saml/csrf`,
                {
                    appId: environment.IAMConfig.auth.appId,
                },
                {
                    withCredentials: true,
                }
            )
            .pipe(
                map((resp) => {
                    this.csrfToken$.next(resp.token);
                    return resp;
                })
            );
    }

    private getCsrfTokenFromLocalStorage(): string | undefined {
        try {
            return localStorage.getItem(this.CSRF_TOKEN_STORAGE_KEY);
        } catch {
            return;
        }
    }

    private getCsrfTokenFromUrl(): string | undefined {
        const hash = window.location.hash;
        if (hash && hash.startsWith('#token=')) {
            const value = new HttpParams({ fromString: hash }).get('#token');
            window.location.hash = ''; // remove token form url
            return value;
        }
    }

    public redirectToLoginScreen(postRedirectUrl?: URL) {
        const url = postRedirectUrl || new URL(window.location.href);
        window.location.href = `${environment.IAMConfig.auth.baseUrl}saml/sso/request?appId=${
            environment.IAMConfig.auth.appId
        }&redirectUrl=${encodeURIComponent(url.toString())}`;
    }

    private isCsrfTokenValid(token: string) {
        try {
            return !this.isCSRFTokenExpired(token);
        } catch (_) {
            return false;
        }
    }

    isCSRFTokenExpired(token: string): boolean {
        const date = this.getTokenExpirationDate(token);
        if (!date) {
            return false;
        }
        return date.valueOf() <= new Date().valueOf();
    }

    getTokenExpirationDate(token: string): Date {
        const decoded = jwtDecode<{ exp: number }>(token);

        if (decoded.exp === undefined) {
            return null;
        }

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    removeTokenFromLocalStorage() {
        localStorage.removeItem(this.CSRF_TOKEN_STORAGE_KEY);
    }

    logout(redirectUrl: string) {
        this.removeTokenFromLocalStorage();
        const form = window.document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute(
            'action',
            `${environment.IAMConfig.auth.baseUrl}auth/v1/saml/logout?redirectUrl=${encodeURIComponent(redirectUrl)}`
        );
        form.setAttribute('target', '_self');

        window.document.body.appendChild(form);
        form.submit();
    }
}
