import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

type Section = 'identity' | 'sales' | 'roles';

@Injectable()
export class UsersService {
    constructor(private http: HttpClient, private transLateService: TranslateService) {}

    getMe(sections: Section[]): Observable<Me> {
        const languageCode = this.transLateService.currentLang ? this.transLateService.currentLang : 'en-US';
        const headers = new HttpHeaders().append('accept-language', languageCode);
        let params = new HttpParams();
        sections.forEach((section: string) => {
            params = params.append('sections', section);
        });
        return this.http.get<Me>(`${environment.http.usersBaseUrl}users/me`, { headers, params }).pipe(
            map((me) => {
                // TODO Remove after fix in Service
                if (me.address?.houseNumber && me.address?.postalCode) {
                    me.address.houseNumber = me.address.houseNumber.replace('undefined', '');
                    me.address.postalCode = me.address.postalCode.replace('undefined', '');
                }

                return me;
            })
        );
    }

    updateMe(updatableUserMe: UpdatableMe, createSapConsumer?: boolean) {
        if (updatableUserMe?.address?.houseNumber) {
            updatableUserMe.address.houseNumber = String(updatableUserMe.address.houseNumber);
        }

        if (updatableUserMe?.address?.postalCode) {
            updatableUserMe.address.postalCode = String(updatableUserMe.address.postalCode);
        }

        if (updatableUserMe?.address?.region) {
            updatableUserMe.address.region = String(updatableUserMe.address.region);
        }

        let params = new HttpParams();
        if (createSapConsumer) {
            params = params.append('createSapConsumer', 'true');
        }

        return this.http.patch<{ error?: object; sapConsumerCreated: boolean }>(
            `${environment.http.usersBaseUrl}users/me`,
            updatableUserMe,
            {
                params,
            }
        );
    }

    createAvatar(avatar: Blob): Observable<{ blobName: string }> {
        const formData: FormData = new FormData();
        formData.append('file', avatar);

        return this.http.post<{ blobName: string }>(`${environment.http.usersBaseUrl}avatar`, formData);
    }

    deleteAvatar(): Observable<void> {
        return this.http.delete<void>(`${environment.http.usersBaseUrl}avatar`);
    }

    createCases(caseData: CaseCreationPayload): Observable<{ id: string }> {
        if (caseData?.houseNumber) {
            caseData.houseNumber = String(caseData?.houseNumber);
        }

        if (caseData?.zipCode) {
            caseData.zipCode = String(caseData.zipCode);
        }

        return this.http.post<{ id: string }>(`${environment.http.usersBaseUrl}cases`, caseData);
    }
}

export interface Me {
    id: string;
    loginId: string;
    avatarLink: string;
    gender: 'MALE' | 'FEMALE' | 'OTHER';
    name: {
        title: string;
        firstName: string;
        familyName: string;
    };
    address: {
        street: string;
        houseNumber: string;
        postalCode: string;
        city: string;
        countryCode: string;
    };
    company: {
        name: string;
        countryCode: string;
        id: string;
        vkorg: string;
    };
    contacts: {
        email: string;
        mobile: CustomerContactData | string;
        telephone: CustomerContactData | string;
    };
    userState: string;
    languageCode: string;
    locale: string;
    properties: { name: string; value: string }[];
    applicationRoles?: string[];
    businessRoles?: string[];
    contactId?: string;
    isLite?: boolean;
    customerName?: string;
}

export interface CustomerContactData {
    country: string;
    number: string;
    extension?: string;
}

export interface UpdatableMe {
    loginId?: string;
    name?: {
        title?: string;
        firstName?: string;
        familyName?: string;
    };
    address?: {
        street?: string;
        houseNumber?: string;
        postalCode?: string;
        city?: string;
        countryCode?: string;
        region?: string;
    };
    contacts?: {
        email?: string;
        mobile?: CustomerContactData | string;
        telephone?: CustomerContactData | string;
    };
    language?: string;
    customerName?: string;
}

export interface CaseCreationPayload {
    phone?: string;
    mobile?: string;
    street: string;
    houseNumber: string;
    zipCode: string;
    city: string;
    countryCode: string;
    firstName: string;
    lastName: string;
    email: string;
}
